<template>
  <div class="overflow-hidden">

    <Banner/>

    <Intro/>

    <Why/>

    <!-- <div class="w-full section-3">
      <div class="flex items-center">
        <div class="font-bold text-5xl header">Why underlie?</div>
        <div class="vr"></div>
        <div class="relative">
          <div class="slider w-full">
            <VueSlickCarousel @beforeChange="checkSlideIndex" ref="carousel" :arrows="true" :dots="true" :slidesToShow="1">
              <div>
                <div class="flex items-center pl-48">
                  <img class="w-52 mr-12" src="../assets/icons/slider-1.svg" alt="">
                  <div class="text-left">
                    <div class="text-4xl font-bold mb-4">Secure</div>
                    <div class="text-lg">underlie uses bank level security with the high-bit rate encryption and modern infrastructure to ensure your customer’s financial data is protected.</div>
                  </div>
                </div>
              </div>
              <div>
                <div class="flex items-center pl-48">
                  <img class="w-52 mr-12" src="../assets/icons/slider-2.svg" alt="">
                  <div class="text-left">
                    <div class="text-4xl font-bold mb-4">Brand Flexible</div>
                    <div class="text-lg">We offer a white-labeled solution with best-in-class technology to build and launch data driven propositions for your customers without worrying about in-house development.</div>
                  </div>
                </div>
              </div>
              <div>
                <div class="flex items-center pl-48">
                  <img class="w-52 mr-12" src="../assets/icons/slider-3.svg" alt="">
                  <div class="text-left">
                    <div class="text-4xl font-bold mb-4">ISO 27001 Compliant</div>
                    <div class="text-lg">underlie’s systems are ISO27001 certified to ensure wekeep up with locally accepted bank security and encryption standards.</div>
                  </div>
                </div>
              </div>
              <div>
                <div class="flex items-center pl-48">
                  <img class="w-52 mr-12" src="../assets/icons/slider-4.svg" alt="">
                  <div class="text-left">
                    <div class="text-4xl font-bold mb-4">One simple integration</div>
                    <div class="text-lg">In a matter of 24 hours you can start testing and running a few functionalities that elevate your customer engagement.</div>
                  </div>
                </div>
              </div>
            </VueSlickCarousel>
          </div>
          <div class="slider-nav flex justify-between w-36">
            <img @click="goToPrev()" src="../assets/icons/right-arrow.svg" class="w-3 cursor-pointer" style="transform:rotate(180deg)" alt="">
            <div class="vr"></div>
            <img @click="goToNext()" src="../assets/icons/right-arrow.svg" class="w-3 cursor-pointer" alt="">
          </div>
        </div>
      </div>
    </div> -->

    <Solutions/>

    <OpenAPI/>
    
    <Contact/>

  </div>
</template>

<script>
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

import Banner from './home/Banner.vue'
import Intro from './home/Intro.vue'
import Why from './home/Why.vue'
import Solutions from './home/Solutions.vue'
import OpenAPI from './home/OpenAPI.vue'
import Contact from './home/Contact.vue'

export default {
  name: "Home",
  components: {
    Banner,
    Intro,
    Why,
    Solutions,
    OpenAPI,
    Contact
  },
  data() {
    return {
      showAccent1: false,
      showAccent2: false,
      currentSlide: 0
    }
  },
  methods: {
    checkSlideIndex(prev, next) {
      this.currentSlide = next;
    },
    goToNext() {
      this.$refs.carousel.next();
    },
    goToPrev() {
      this.$refs.carousel.prev();
    },
    handleScroll() {
      // console.log(document.body.getBoundingClientRect().top)
      if (document.body.getBoundingClientRect().top < -200) {
        this.showAccent1 = true;
      } else {
        this.showAccent1 = false;
      }
      if (document.body.getBoundingClientRect().top < -900) {
        this.showAccent2 = true;
      } else {
        this.showAccent2 = false;
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  }
};
</script>
 
 <style lang="scss" scoped>
  .accent1 {
    top: -130px;
    transition: all 1s;
    &.show {
      top: 0;
    }
  }
  .accent2 {
    top: 130px;
    transition: all 1s;
    &.show {
      top: 0;
    }
  }
 </style>