<template>
  <div class="overflow-hidden">
    <div class="mt-24">
        <div class="flex justify-around items-center">
            <img src="../../assets/images/landing-1.png" class="mt-20" alt="">
            <div class="max-w-xl text-left">
                <div class="text-main text-3xl opacity-50 uppercase font-bold">Payment <span class="font-bold">API</span></div>
                <div class="text-6xl font-bold mb-8">Authorize and initiate bank account transfers</div>
                <div class="text-xl my-4">With underlie’s Payment Initiation API, your customers can now authorize and initiate bank account transfers seamlessly from within any 3rd party application. </div>
                <!-- <div>[This feature is currently only available for banks]</div> -->
                <div @click="$scrollToView('contact')"  class="button bg-main rounded-lg text-white py-2 px-4 font-bold w-56 text-center h-12 flex justify-center items-center cursor-pointer mt-8">Read the Documentation</div>
            </div>
        </div>
    </div>

    <div class="text-center text-3xl text-main mb-0 mt-20">Three easy steps to transfer using ACH or Swift:</div>
    <div class="px-36 min-h-25rem flex items-center">
        <div class="flex justify-around items-center w-full">
            <div class="flex flex-col items-center justify-center max-w-sm">
                <img src="../../assets/icons/personalize-bank-exp.png" class="w-28 mb-4" alt="">
                <div class="text-2xl leading-none font-bold">Step 1 </div>
                <div class="bar-1 w-20 my-4 text-lg"></div>
                <div>Connect bank using Auth^2 API to ensure there is a payment source with existing balance</div>
            </div>
            <div class="flex flex-col items-center justify-center max-w-sm">
                <img src="../../assets/icons/personalize-bank-exp.png" class="w-28 mb-4" alt="">
                <div class="text-2xl leading-none font-bold">Step 2</div>
                <div class="bar-1 w-20 my-4 text-lg"></div>
                <div>Create a beneficiary using same bank transfer form requests  </div>
            </div>
            <div class="flex flex-col items-center justify-center max-w-sm">
                <img src="../../assets/icons/personalize-bank-exp.png" class="w-28 mb-4" alt="">
                <div class="text-2xl leading-none font-bold">Step 3
                     </div>
                <div class="bar-1 w-20 my-4 text-lg"></div>
                <div>Initiate payment on behalf of customer and add amount </div>
            </div>

        </div>
    </div>

    <hr style="height: 1px;" >
    <div id="contact" class="w-full pb-40">
      <div class="text-center text-5xl text-main mb-12">Get In Touch</div>
      <div class="flex flex-col items-center justify-center w-1/2 mx-auto">
        <div class="flex w-full mb-6">
          <input class="w-full border border-main mr-3" type="text" placeholder="Name">
          <input class="w-full border border-main ml-3" type="email" placeholder="Email">
        </div>
        <textarea class="w-full border border-main" placeholder="Message" name="message" cols="30" rows="5"></textarea>
        <button type="submit" class="flex items-center text-xl mt-6 w-32 justify-center text-black bg-main text-white px-4 py-0 rounded-lg">
          Send
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showAccent1: false,
      showAccent2: false
    }
  },
  methods: {
    handleScroll() {
      console.log(document.body.getBoundingClientRect().top)
      if (document.body.getBoundingClientRect().top < -200) {
        this.showAccent1 = true;
      } else {
        this.showAccent1 = false;
      }
      if (document.body.getBoundingClientRect().top < -900) {
        this.showAccent2 = true;
      } else {
        this.showAccent2 = false;
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  }
};
</script>
 
 <style lang="scss" scoped>
  .accent1 {
    top: -130px;
    transition: all 1s;
    &.show {
      top: 0;
    }
  }
  .accent2 {
    top: 130px;
    transition: all 1s;
    &.show {
      top: 0;
    }
  }
 </style>