<template>
    <div>
        <!-- Section 1 -->
        <div class="section-1 h-full relative">

        <div class="flex flex-col md:flex-row justify-around md:items-center h-full md:pt-0 md:pl-20 px-8 md:px-0">
            <div class="text-left text-white flex-shrink max-w-3xl">
            <div class="w-full">
                <div data-aos="fade-down" data-aos-duration="1500"  class="text-2xl md:text-6xl mb-0 md:mb-6 typing min-h-36">
                <span class="">Simple APIs that transform your</span>
                <vue-typed-js :loop="true" :strings="['online banking apps', 'lending apps', 'investment apps', 'personal finance apps', 'insurance apps', 'e-commerce apps']" :showCursor="false" :typeSpeed="100">
                    <span class="font-bold text-2xl md:text-6xl opacity-75 typing"></span>
                </vue-typed-js>
                </div>
                <div data-aos="fade-up" data-aos-duration="1500"  class="text-2xl" style="margin-top:-2rem">Our secure APIs seamlessly integrate with banks and fintech companies to empower their products to make better, data-driven and automated decisions.</div>
                <div class="mt-12 md:flex items-center">
                <div class="font-bold text-2xl mr-10 it opacity-75 mt-2">As seen on</div>
                <div class="flex items-center mt-4 md:mt-0">
                    <img src="../../assets/images/as-seen-1.png" class="h-8 md:h-10 mr-8" alt="">
                    <img src="../../assets/images/as-seen-2.png" class="h-14 md:h-16" alt="">
                </div>
                </div>
            </div>
            </div>
            <div data-aos="zoom-in" data-aos-duration="1500" class="hidden md:block flex-shrink w-full" style="max-width:42rem;">
                <img class="image-1 max-w-full mx-auto" src="../../assets/images/landing-3.gif" />
            </div>   
            <!-- <div data-aos="zoom-in" data-aos-duration="1500" class="md:hidden flex-shrink w-full">
                <img class="image-1 max-w-full mx-auto" src="../../assets/images/landing-3.gif" />
            </div>         -->
        </div>
        
        <!-- <img data-aos="fade-right" data-aos-duration="1500" data-aos-delay="500" class="accent-1" src="../../assets/images/accent-1.svg" alt=""> -->
        </div>

        <div class="relative z-m2" style="transform:scaleY(0.5)">
        <svg class="absolute top-0 z-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <defs>
            <linearGradient spreadMethod="pad" id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style="stop-color:rgb(47, 64, 81);stop-opacity:1;" />
                <stop offset="50%" style="stop-color:rgb(40, 55, 69);stop-opacity:1;" />
                <stop offset="100%" style="stop-color:rgb(33, 47, 59);stop-opacity:1;" />
            </linearGradient>
            </defs>
            <path fill="url(#gradient)" fill-opacity="1" d="M0,256L48,261.3C96,267,192,277,288,250.7C384,224,480,160,576,144C672,128,768,160,864,170.7C960,181,1056,171,1152,138.7C1248,107,1344,53,1392,26.7L1440,0L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path>
        </svg>
        <!-- <svg :class="{'show' : showAccent1}" class="accent1 absolute top-0 z-m1 mt-12" style="transform:scale(1.3)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#004F78" fill-opacity="1" d="M0,256L48,261.3C96,267,192,277,288,250.7C384,224,480,160,576,144C672,128,768,160,864,170.7C960,181,1056,171,1152,138.7C1248,107,1344,53,1392,26.7L1440,0L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path></svg> -->
        <!-- <svg class="absolute top-0 z-m1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#009E83" fill-opacity="1" d="M0,32L80,74.7C160,117,320,203,480,229.3C640,256,800,224,960,229.3C1120,235,1280,277,1360,298.7L1440,320L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path></svg> -->
        </div>
        <div class="h-52 w-full"></div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>