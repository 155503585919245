import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

// Products
import Authentication from '../views/products/Authentication.vue';
import Authorization from '../views/products/Authorization.vue';
import Data from '../views/products/Data.vue';
import Payment from '../views/products/Payment.vue';
import Auth from '../views/products/Auth.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/products/authentication",
    name: "authentication",
    component: Authentication,
  },
  {
    path: "/products/authorization",
    name: "authorization",
    component: Authorization,
  },
  {
    path: "/products/auth",
    name: "auth",
    component: Auth,
  },
  {
    path: "/products/data",
    name: "data",
    component: Data,
  },
  {
    path: "/products/payment",
    name: "payment",
    component: Payment,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
