<template>
  <div class="overflow-hidden">
    <!-- Authentication  -->
    <div id="authentication">
        <div class="mt-24">
            <div class="flex justify-around items-center">
                <img src="../../assets/images/landing-1.png" class="mt-20" alt="">
                <div class="max-w-xl text-left">
                    <div class="text-main text-3xl opacity-50 uppercase font-bold">Auth<sup class="font-bold pl-1">2</sup></div>
                    <div class="text-6xl font-bold mb-8">Authentication API</div>
                    <div class="text-xl my-4">Strong Customer Authentication (SCA) using PSD2 standards</div>
                    <!-- <div>[This feature is currently only available for banks]</div> -->
                    <div @click="$scrollToView('contact')" class="button bg-main rounded-lg text-white py-2 px-4 font-bold w-56 text-center h-12 flex justify-center items-center cursor-pointer mt-8">Read the Documentation</div>
                </div>
            </div>
        </div>
        <div class="px-36 min-h-30rem flex items-center">
            <div class="flex justify-around items-center w-full">
                <div class="flex flex-col items-center justify-center max-w-sm">
                    <img src="../../assets/icons/personalize-bank-exp.png" class="w-28 mb-4" alt="">
                    <div class="text-2xl font-bold">Knowledge </div>
                    <div class="bar-1 w-20 my-4 text-lg"></div>
                    <div>Something the user knows, such as a password, PIN, etc.</div>
                </div>
                <div class="flex flex-col items-center justify-center max-w-sm">
                    <img src="../../assets/icons/personalize-bank-exp.png" class="w-28 mb-4" alt="">
                    <div class="text-2xl font-bold">Possessions</div>
                    <div class="bar-1 w-20 my-4 text-lg"></div>
                    <div>Something the user has, such as mobile phone, token, or card.</div>
                </div>
                <div class="flex flex-col items-center justify-center max-w-sm">
                    <img src="../../assets/icons/personalize-bank-exp.png" class="w-28 mb-4" alt="">
                    <div class="text-2xl font-bold">Inherence</div>
                    <div class="bar-1 w-20 my-4 text-lg"></div>
                    <div>Something the user is, such as facial recognition, fingerprint, or recaptcha, phone pin</div>
                </div>

            </div>
        </div>
    </div>
    <hr style="height: 1px;">
    <!-- Authorization -->
    <div id="authorization">
        <div class="mt-24">
            <div class="flex flex-row-reverse justify-around items-center">
                <img src="../../assets/images/landing-1.png" class="mt-20" alt="">
                <div class="max-w-xl text-left ml-20">
                    <div class="text-main text-3xl opacity-50 uppercase font-bold">Auth<sup class="font-bold pl-1">2</sup></div>
                    <div class="text-6xl font-bold mb-8">Authorization API</div>
                    <div class="text-xl my-4">Introducing a secure bilateral agreement structure for information sharing to 3rd party apps</div>
                    <!-- <div>[This feature is currently only available for banks]</div> -->
                    <div @click="$scrollToView('contact')" class="button bg-main rounded-lg text-white py-2 px-4 font-bold w-56 text-center h-12 flex justify-center items-center cursor-pointer mt-8">Read the Documentation</div>
                </div>
            </div>
        </div>
        <div class="px-36 min-h-30rem flex items-center">
            <div class="flex justify-around items-center w-full">
                <div class="flex flex-col items-center justify-center max-w-sm">
                    <img src="../../assets/icons/personalize-bank-exp.png" class="w-28 mb-4" alt="">
                    <div class="text-2xl font-bold">Authorization </div>
                    <div class="bar-1 w-20 my-4 text-lg"></div>
                    <div>Allowing customers to authorize the sharing of their personal information, balance, and transaction history to a 3rd party app</div>
                </div>
                <div class="flex flex-col items-center justify-center max-w-sm">
                    <img src="../../assets/icons/personalize-bank-exp.png" class="w-28 mb-4" alt="">
                    <div class="text-2xl font-bold">Notification</div>
                    <div class="bar-1 w-20 my-4 text-lg"></div>
                    <div>Customer is notified via text message that they shared their information</div>
                </div>
                <div class="flex flex-col items-center justify-center max-w-sm">
                    <img src="../../assets/icons/personalize-bank-exp.png" class="w-28 mb-4" alt="">
                    <div class="text-2xl font-bold">Transparency</div>
                    <div class="bar-1 w-20 my-4 text-lg"></div>
                    <div>Customer knows the who, what, and how long this data is being shared</div>
                </div>

            </div>
        </div>
    </div>
    <hr style="height: 1px;">
    <div id="contact" class="w-full pb-40">
      <div class="text-center text-5xl text-main mb-12">Get In Touch</div>
      <div class="flex flex-col items-center justify-center w-1/2 mx-auto">
        <div class="flex w-full mb-6">
          <input class="w-full border border-main mr-3" type="text" placeholder="Name">
          <input class="w-full border border-main ml-3" type="email" placeholder="Email">
        </div>
        <textarea class="w-full border border-main" placeholder="Message" name="message" cols="30" rows="5"></textarea>
        <button type="submit" class="flex items-center text-xl mt-6 w-32 justify-center text-black bg-main text-white px-4 py-0 rounded-lg">
          Send
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showAccent1: false,
      showAccent2: false
    }
  },
  methods: {
    handleScroll() {
      console.log(document.body.getBoundingClientRect().top)
      if (document.body.getBoundingClientRect().top < -200) {
        this.showAccent1 = true;
      } else {
        this.showAccent1 = false;
      }
      if (document.body.getBoundingClientRect().top < -900) {
        this.showAccent2 = true;
      } else {
        this.showAccent2 = false;
      }
    },
    scrollTo(id) {
      document.querySelector('#' + id).scrollIntoView({
        behavior: 'smooth'
      });
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    if (this.$route.query.section) {
      this.scrollTo(this.$route.query.section);
    }
  }
};
</script>
 
 <style lang="scss" scoped>
  .accent1 {
    top: -130px;
    transition: all 1s;
    &.show {
      top: 0;
    }
  }
  .accent2 {
    top: 130px;
    transition: all 1s;
    &.show {
      top: 0;
    }
  }
 </style>