<template>
    <div class="mt-0 md:mt-32">
        <!-- Get In Touch -->
        <!-- <div class="relative z-m2" style="margin-bottom: 20rem; margin-top: -3rem">
        <svg :class="{'show' : showAccent2}"  class="accent2 absolute top-0 z-1"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path fill="#E5E3E2" fill-opacity="1" d="M0,128L120,138.7C240,149,480,171,720,165.3C960,160,1200,128,1320,112L1440,96L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path>
        </svg>
        <svg class="absolute z-1 mt-12" style="transform: rotate(2deg); width: 130%; top: -3rem; left: -6rem;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path fill="#838180" fill-opacity="1" d="M0,128L120,138.7C240,149,480,171,720,165.3C960,160,1200,128,1320,112L1440,96L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path>
        </svg>
        </div> -->
        <div id="contact" class="w-full pb-40 pt-12 md:pt-28 relative">
        <img data-aos="fade-left" data-aos-duration="1500" data-aos-delay="0" src="../../assets/images/accent-3.svg" class="hidden md:block accent-4" alt="">
        <img data-aos="fade-right" data-aos-duration="1500" data-aos-delay="500" src="../../assets/images/accent-4.svg" class="hidden md:block accent-5" alt="">
        <img data-aos="fade-up" data-aos-duration="1500" data-aos-delay="1000" src="../../assets/images/accent-9.svg" class="hidden md:block accent-6" alt="">
        <img data-aos="fade-down" data-aos-duration="1500" data-aos-delay="1500" src="../../assets/images/accent-5.svg" class="hidden md:block accent-7" alt="">
        <div class="text-center text-4xl md:text-5xl mb-12">Get In Touch</div>
        <form action="mailto:akram@underlie.tech" method="POST" enctype="multipart/form-data" name="Contact Us">
            <div class="flex flex-col items-center justify-center px-8 md:px-0 md:w-1/2 mx-auto">
            <div class="md:flex w-full mb-4 md:mb-6">
                <input class="w-full md:mr-3 mb-4 md:mb-0" type="text" placeholder="Name">
                <input class="w-full md:ml-3" type="email" placeholder="Email">
            </div>
            <div class="md:flex w-full mb-4 md:mb-6">
                <input class="w-full md:mr-3 mb-4 md:mb-0" type="text" placeholder="Work Phone">
                <input class="w-full md:ml-3" type="text" placeholder="Company">
            </div>
            <textarea class="w-full" placeholder="Message" name="message" cols="30" rows="5"></textarea>
            <button type="submit" class="flex items-center text-xl mt-6 w-32 justify-center">
                <span>Send</span>
                <!-- <img src="../../assets/icons/direct.png" class="w-4 ml-3" alt=""> -->
            </button>
            </div>
        </form>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>