<template>
    <div>
        <div class="hidden md:block">
            <!-- Section 4 -->
            <!-- <div id="for-banks" class="font-bold text-5xl section-header mt-32">Banking Solutions</div> -->
            <div id="for-banks" class="section-4 md:flex mt-32">
            <div class="animation-card-1 md:w-1/2 borderr borderb">
                <div class="content">
                <div class="flex justify-between items-end mb-8">
                    <div class="icon">
                    <img src="../../assets/icons/data.svg" alt="">
                    <div class="title pfm">
                        Personal Finance Management API
                        <div class="api-tag">Banking Solutions</div>
                    </div>
                    </div>
                    <img class="arrow h-8" src="../../assets/icons/right-arrow-white.svg" alt="">
                </div>
                <div class="wrapper">
                    <div class="header">Problem</div>
                    <div class="text">Bank customers currently use their online banking to check on their balance or transfers. Customers don’t have the insights or engagement functionality to utilize their online banking applications more.</div>
                    <div class="header">underlie’s Solution</div>
                    <div class="text">
                    We transforming online banking apps to personal finance apps 
                    <ul>
                        <li>
                        Leveraging spending patterns to tailor new benefits for bank customers 
                        </li>
                        <li>
                        Increase customer portfolio stickiness based on a personalized dashboard
                        </li>
                        <li>
                        Empower customer insight with lending options based on pre-set credit underwriting criteria
                        </li>
                    </ul>
                    </div>
                </div>
                </div>
            </div>
            <div class="animation-card-1 md:w-1/2 borderb">
                <div class="content">
                <div class="flex justify-between items-end mb-8">
                    <div class="icon">
                    <img src="../../assets/icons/auth.svg" alt="">
                    <div class="title">
                        E-KYC API
                        <div class="api-tag">Banking Solutions</div>
                    </div>
                    </div>
                    <img class="arrow h-8" src="../../assets/icons/right-arrow-white.svg" alt="">
                </div>
                <div class="wrapper">
                    <div class="header">Problem</div>
                    <div class="text">Current login offerings for bank products (online banking or wallet) can provide much faster account access methods while also inheriting the required authentication.</div>
                    <div class="header">underlie’s Solution</div>
                    <div class="text">
                    Underlie offers an e-KYC solution that reduces CHURN by offering an A-Z authentication formula that increases account access
                    <ul>
                        <li>
                        Strong Customer Authentication (SCA) per European PSD2 requirements
                        </li>
                        <li>
                        Reduce fraud attempts by verifying bank customers using their bank data 
                        </li>
                        <li>
                        Create phone to bank key identifiers to link a phone to a bank account for faster login (ex. face ID and phone key pair security)
                        </li>
                    </ul>
                    </div>
                </div>
                </div>
            </div>
            </div>

            <!-- Section 4 -->
            <!-- <div id="for-fintechs" class="font-bold text-5xl section-header">Fintech/Business Solutions</div> -->
            <div id="for-fintechs" class="section-4 flex">
            <div class="animation-card-1 md:w-1/2 borderr">
                <div class="content">
                <div class="flex justify-between items-end mb-8">
                    <div class="icon">
                    <img src="../../assets/icons/underlie-score.svg" style="width:65px;" alt="">
                    <div class="title uppercase score">
                        underlie Score
                        <div class="api-tag">Fintech/Business Solutions</div>
                    </div>
                    </div>
                    <img class="arrow h-8" src="../../assets/icons/right-arrow-white.svg" alt="">
                </div>
                <div class="wrapper">
                    <div class="header">Problem</div>
                    <div class="text">Currently companies rely on I-score (small subset &lt;2% of population) or their own in-houseformulas to vaguely decide the willingness and capability of a customer or entity.</div>
                    <div class="header">underlie’s Solution</div>
                    <div class="text">
                    underlie offers an extensive scoring combination of data points and short questionnaires that we can utilize to asses a customers financial health and loan risk factor:
                    <ul>
                        <li>
                        AI modeling from 85% to 99% confidence scoring depending on data provided by customer 
                        </li>
                        <li>
                        Alternative credit-scoring based on a number of data point
                        </li>
                        <li>
                        Works with banked and unbanked customers
                        </li>
                    </ul>
                    </div>
                </div>
                </div>
            </div>
            <div class="animation-card-1 md:w-1/2 ">
                <div class="content">
                <div class="flex justify-between items-end mb-8">
                    <div class="icon">
                    <img style="width:65px" src="../../assets/icons/dpg.png" alt="">
                    <div class="title dpg">
                        <!-- Dynamic Payment Gateway (DPG) -->
                        UnderlieX 
                        <span class="uppercase">Issuing</span>
                        <div class="api-tag">Fintech/Business Solutions</div>
                    </div>
                    </div>
                    <img class="arrow h-8" src="../../assets/icons/right-arrow-white.svg" alt="">
                </div>
                <div class="wrapper">
                    <div class="header">Problem</div>
                    <div class="text">Currently issuing a branded card for your loyal customers is a long and tedious process with many steps that the typical fintech/merchant isn't ready to take. </div>
                    <div class="header">underlie’s Solution</div>
                    <div class="text">
                    We develop physical and virtual co-branded cards for your loyal  customers to bring them closer to your brand.
                    <ul>
                        <li>
                        Monetary/non-monetary plug and play integrations for your customers
                        </li>
                        <li>
                        Data insights on your customers payments
                        </li>
                        <li>
                        Instant rewards on the app that customers can use at anytime (no expiry) 
                        </li>
                        <li>
                        Your branded cards are always in your customer’s pockets without the hassle of coupons, etc. 
                        </li>
                    </ul>  
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        <div class="md:hidden">
            <hr>
            <div class="flex justify-around lg:flex-nowrap flex-wrap px-8">
                <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="0" class="pr-4 mb-8">
                    <img class="w-12 md:w-16 mb-4 mr-8" src="../../assets/icons/data.svg" alt="">
                    <div class="text-left max-w-xs">
                        <div class="text-xl md:text-2xl font-bold mb-2">
                            Personal Finance Management API
                            <div class="api-tag">Banking Solutions</div>
                        </div>
                        <br>
                        <div class="text-sm md:text-base"><span class="font-bold">Problem:</span> Bank customers currently use their online banking to check on their balance or transfers. Customers don’t have the insights or engagement functionality to utilize their online banking applications more.</div>
                        <br>
                        <div class="text-sm md:text-base"><span class="font-bold">Solution:</span>
                            We transforming online banking apps to personal finance apps 
                            <ul>
                                <li>
                                Leveraging spending patterns to tailor new benefits for bank customers 
                                </li>
                                <li>
                                Increase customer portfolio stickiness based on a personalized dashboard
                                </li>
                                <li>
                                Empower customer insight with lending options based on pre-set credit underwriting criteria
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="0" class="pr-4 mb-8">
                    <img class="w-12 md:w-16 mb-4 mr-8" src="../../assets/icons/auth.svg" alt="">
                    <div class="text-left max-w-xs">
                        <div class="text-xl md:text-2xl font-bold mb-2">
                            E-KYC API
                            <div class="api-tag">Banking Solutions</div>
                        </div>
                        <br>
                        <div class="text-sm md:text-base"><span class="font-bold">Problem:</span> Current login offerings for bank products (online banking or wallet) can provide much faster account access methods while also inheriting the required authentication.</div>
                        <br>
                        <div class="text-sm md:text-base"><span class="font-bold">Solution:</span>
                            Underlie offers an e-KYC solution that reduces CHURN by offering an A-Z authentication formula that increases account access
                            <ul>
                                <li>
                                Strong Customer Authentication (SCA) per European PSD2 requirements
                                </li>
                                <li>
                                Reduce fraud attempts by verifying bank customers using their bank data 
                                </li>
                                <li>
                                Create phone to bank key identifiers to link a phone to a bank account for faster login (ex. face ID and phone key pair security)
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="0" class="pr-4 mb-8">
                    <img class="w-12 md:w-16 mb-4 mr-8" src="../../assets/icons/underlie-score.svg" alt="">
                    <div class="text-left max-w-xs">
                        <div class="text-xl md:text-2xl font-bold mb-2">
                            underlie Score
                            <div class="api-tag">Fintech/Business Solutions</div>
                        </div>
                        <br>
                        <div class="text-sm md:text-base"><span class="font-bold">Problem:</span> Currently companies rely on I-score (small subset &lt;2% of population) or their own in-houseformulas to vaguely decide the willingness and capability of a customer or entity.</div>
                        <br>
                        <div class="text-sm md:text-base"><span class="font-bold">Solution:</span>
                            underlie offers an extensive scoring combination of data points and short questionnaires that we can utilize to asses a customers financial health and loan risk factor:
                            <ul>
                                <li>
                                AI modeling from 85% to 99% confidence scoring depending on data provided by customer 
                                </li>
                                <li>
                                Alternative credit-scoring based on a number of data point
                                </li>
                                <li>
                                Works with banked and unbanked customers
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="0" class="pr-4 mb-8">
                    <img class="w-12 md:w-16 mb-4 mr-8" src="../../assets/icons/dpg.svg" alt="">
                    <div class="text-left max-w-xs">
                        <div class="text-xl md:text-2xl font-bold mb-2">
                            UnderlieX ISSUING
                            <div class="api-tag">Fintech/Business Solutions</div>
                        </div>
                        <br>
                        <div class="text-sm md:text-base"><span class="font-bold">Problem:</span> Currently issuing a branded card for your loyal customers is a long and tedious process with many steps that the typical fintech/merchant isn't ready to take. </div>
                        <br>
                        <div class="text-sm md:text-base"><span class="font-bold">Solution:</span>
                            We develop physical and virtual co-branded cards for your loyal  customers to bring them closer to your brand.
                            <ul>
                                <li>
                                Monetary/non-monetary plug and play integrations for your customers
                                </li>
                                <li>
                                Data insights on your customers payments
                                </li>
                                <li>
                                Instant rewards on the app that customers can use at anytime (no expiry) 
                                </li>
                                <li>
                                Your branded cards are always in your customer’s pockets without the hassle of coupons, etc. 
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>