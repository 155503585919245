<template>
  <div>
      <!-- Section 2 -->
        <div id="about-us" class="section-2 h-full relative px-8 md:px-0">
        <div class="h-full w-full md:flex items-center justify-around">
            <div class="text-left">
            <div class="max-w-lg ml-auto relative">
                <div data-aos="fade-down" data-aos-duration="1500" class="text-3xl md:text-5xl mb-0 md:mb-4 typing min-h-24">Powering modern fintech solutions</div>
                <div data-aos="fade-up" data-aos-duration="1500" class="text-lg md:text-xl">Short for underlying technology, underlie leverages open-api technology and machine learning to empower banks and fintech companies to provide their customers with better services and benefits. </div>
                <img data-aos="fade-down-left" data-aos-duration="1500"  class="accent-2 h-20 md:h-auto" src="../../assets/images/accent-6.svg" alt="">
                <img data-aos="fade-up-right" data-aos-duration="1500"  class="accent-3 h-20 md:h-auto" src="../../assets/images/accent-7.svg" alt="">
            </div>
            </div>
            <!-- <img alt="Vue logo" class="w-2/5" src="../../assets/images/landing-1.png" /> -->
            <img data-aos="zoom-in" data-aos-duration="1500" class="md:w-1/3" src="../../assets/images/landing-4.svg" alt="">
        </div>
        </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>