import Vue from "vue";
import App from "./App.vue";
import router from "./router";

// CSS
import "@/assets/css/fonts.css";
import "@/assets/css/global.scss";

// AOS
import AOS from "aos";
import "aos/dist/aos.css";
App.AOS = new AOS.init({ disable: "phone" });

// TypedJS
import VueTypedJs from 'vue-typed-js';
Vue.use(VueTypedJs);

// Transition
import VuePageTransition from 'vue-page-transition'
Vue.use(VuePageTransition)

// Scroll To
import VueScrollTo from 'vue-scrollto'
Vue.use(VueScrollTo)

Vue.config.productionTip = false;

Vue.prototype.$scrollToView = (id) => {
  var element = document.getElementById(id);
  var headerOffset = 200;
  var elementPosition = element.getBoundingClientRect().top;
  var offsetPosition = elementPosition - headerOffset + document.documentElement.scrollTop;
  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth'
  });
}

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
