<template>
  <div :class="{'scroll' : navState == 'scroll' || $route.name != 'Home'}" class="navbar flex items-center justify-between py-6 md:py-12 px-8 md:px-16">
    <router-link tag="div" :to="{name: 'Home'}" class="flex items-center cursor-pointer">
      <transition name="fade">
        <img @click="scrollToTop" v-if="navState == 'initial'" src="../assets/images/logo-white.svg" class="w-28 md:w-36" alt="">
        <img @click="scrollToTop" v-else src="../assets/images/logo-alt.png" class="w-28 md:w-36" alt="">
      </transition>
      <!-- <div class="logo-type text-2xl pt-2">underlie</div> -->
    </router-link>
    <div data-aos="fade-down" data-aos-duration="1500" data-aos-delay="500" class="hidden md:flex items-center font-bold">
      <!-- <div class="nav-link products no-underline">
        Products
        <img v-if="navState == 'scroll'" src="../assets/icons/caret-down.svg" width="8px" style="margin-left: 6px;opacity:0.7;" alt="">
        <img v-else src="../assets/icons/caret-down-white.svg" width="8px" style="margin-left: 6px;" alt="">
        <div class="dropdown-wrapper">
          <div class="dropdown">
            <router-link tag="div" :to="{name: 'data'}" class="dropdown-item">
              <img src="../assets/icons/dropdown-1.png" class="w-10 mr-6" alt="">
              <span class="text-left whitespace-normal">Personal Finance Management API</span>
            </router-link>
            <router-link tag="div" :to="{name: 'auth'}" v-scroll-to="'#authentication'" class="dropdown-item">
              <img src="../assets/icons/dropdown-2.png" class="w-10 mr-6" alt="">
              <span class="text-left">E-KYC API</span>
            </router-link>
            <router-link tag="div" :to="{name: 'auth'}" v-scroll-to="'#authorization'" class="dropdown-item">
              <img src="../assets/icons/dropdown-3.png" class="w-10 mr-6" alt="">
              <span class="text-left whitespace-normal">Dynamic Payment Gateway (DPG)</span>
            </router-link>
            <router-link tag="div" :to="{name: 'payment'}" class="dropdown-item">
              <img src="../assets/icons/dropdown-4.png" class="w-10 mr-6" alt="">
              <span class="text-left whitespace-normal">underlie Score</span>
            </router-link>
          </div>
        </div>
      </div> -->
      <div @click="scrollTo('about-us')" class="nav-link">About Us</div>
      <div @click="scrollTo('why')" class="nav-link">Why underlie?</div>
      <!-- <div class="nav-link no-underline">
        For Banks
        <img v-if="navState == 'scroll'" src="../assets/icons/caret-down.svg" width="8px" style="margin-left: 6px;opacity:0.7;" alt="">
        <img v-else src="../assets/icons/caret-down-white.svg" width="8px" style="margin-left: 6px;" alt="">
        <div class="dropdown">
          <div class="dropdown-item">
            <img src="../assets/icons/dropdown-6.png" class="w-10 mr-6" alt="">
            <span class="text-left">PFM</span>
          </div>
          <div class="dropdown-item">
            <img src="../assets/icons/dropdown-7.png" class="w-10 mr-6" alt="">
            <span class="text-left">Open API Consulting</span>
          </div>
        </div>
      </div>
      <div class="nav-link no-underline">
        For Fintechs
        <img v-if="navState == 'scroll'" src="../assets/icons/caret-down.svg" width="8px" style="margin-left: 6px;opacity:0.7;" alt="">
        <img v-else src="../assets/icons/caret-down-white.svg" width="8px" style="margin-left: 6px;" alt="">
        <div class="dropdown">
          <div class="dropdown-item">
            <img src="../assets/icons/dropdown-8.png" class="w-10 mr-6" alt="">
            <span class="text-left">Investment Platforms</span>
          </div>
          <div class="dropdown-item">
            <img src="../assets/icons/dropdown-9.png" class="w-10 mr-6" alt="">
            <span class="text-left">Lending</span>
          </div>
          <div class="dropdown-item">
            <img src="../assets/icons/dropdown-10.png" class="w-10 mr-6" alt="">
            <span class="text-left">Digital Wallet</span>
          </div>
          <div class="dropdown-item">
            <img src="../assets/icons/dropdown-11.png" class="w-10 mr-6" alt="">
            <span class="text-left">KYC</span>
          </div>
        </div>
      </div> -->
      <div @click="scrollTo('for-banks')" class="nav-link">For Banks</div>
      <div @click="scrollTo('for-fintechs')" class="nav-link">For Fintechs</div>
      <!-- <router-link tag="div" :to="{name: 'About'}" class="nav-link">Documentation</router-link> -->
      <div @click="scrollTo('open-api')" class="nav-link">Open API</div>

      <a href="#" target="_blank" class="nav-link">Blog</a>
      <!-- <div @click="scrollTo('contact')" class="nav-link get-in-touch no-underline">
        Get In Touch
        <img v-if="navState == 'scroll'" src="../assets/icons/right-arrow.svg" class="ml-3" width="12px" height="12px" alt="">
        <img v-else src="../assets/icons/right-arrow.svg" class="ml-3" width="12px" height="12px" alt="">
      </div> -->
      <div @click="scrollTo('contact')" class="get-in-touch-link font-bold ml-4 cursor-pointer">
        Get In Touch
      </div>
    </div>
    <!-- MOBILE MENU -->
    <div class="mobile-menu md:hidden">
      <div @click="showMenu = !showMenu" class="mobile-menu__trigger cursor-pointer flex flex-col justify-between items-center">
        <div class="line w-full rounded-md"></div>
        <div class="line w-full rounded-md"></div>
        <div class="line w-full rounded-md"></div>
      </div>
      <div v-if="showMenu" class="fixed top-0 left-0 bg-white h-full w-full">
        <div @click="showMenu = false" class="absolute top-0 right-0 text-main text-4xl mr-8 mt-4 cursor-pointer">&times;</div>
        <div class="h-full flex flex-col justify-center pl-4">
          <div @click="() => {showMenu = false; scrollTo('about-us')}" class="text-lg nav-link">About Us</div>
          <div @click="() => {showMenu = false; scrollTo('why')}" class="text-lg nav-link">Why underlie?</div>
          <div @click="() => {showMenu = false; scrollTo('for-banks')}" class="text-lg nav-link">For Banks</div>
          <div @click="() => {showMenu = false; scrollTo('for-fintechs')}" class="text-lg nav-link">For Fintechs</div>
          <div @click="() => {showMenu = false; scrollTo('open-api')}" class="text-lg nav-link">Open API</div>
          <a href="#" target="_blank" class="text-lg nav-link">Blog</a>
          <div @click="() => {showMenu = false; scrollTo('contact')}" class="text-lg get-in-touch-link font-bold ml-4 cursor-pointer">
            Get In Touch
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      navState: 'initial',
      showMenu: false
    }
  },
  methods: {
    scrollTo(id) {
      // var element = document.getElementById(id);
      // var headerOffset = 200;
      // var elementPosition = element.getBoundingClientRect().top;
      // var offsetPosition = elementPosition - headerOffset;

      // console.log(offsetPosition)

      // window.scrollTo({
      //     top: offsetPosition,
      //     behavior: "smooth"
      // });
      document.querySelector('#' + id).scrollIntoView({ 
        behavior: 'smooth' ,
        block: 'start'
      });
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    handleScroll() {
      if (document.body.getBoundingClientRect().top < -50) {
        this.navState = 'scroll';
      } else {
        this.navState = 'initial';
      }
      // console.log(this.navState)
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  }
};
</script>
