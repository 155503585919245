<template>
    <div>
        <!-- Section 3 -->
        <div id="why" class="section-3 px-8 md:px-12 mt-20">
        <div class="flex mb-8 md:mb-16">
            <div class="font-bold text-3xl md:text-5xl header md:pl-12 flex items-center">
                <div>Why</div>
                <img src="../../assets/images/logo-inline.svg" class="w-40 md:w-48 mx-3" alt="">
                <div>?</div>
            </div>
            <div class="mx-auto" style="opacity:0.07;">
                <!-- <img data-aos="fade-right" data-aos-duration="1500" data-aos-delay="0" class="mb-8" src="../../assets/images/accent-10.svg" alt=""> -->
                <img data-aos="fade-left" data-aos-duration="1500" data-aos-delay="0" src="../../assets/images/accent-4.svg" alt="">
            </div>
        </div>
        <div class="flex justify-around lg:flex-nowrap flex-wrap">
            <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="0" class="pr-4 mb-8">
            <img class="w-12 md:w-16 mb-4 mr-8" src="../../assets/icons/slider-1.svg" alt="">
            <div class="text-left max-w-xs">
                <div class="text-xl md:text-2xl font-bold mb-2">Secure</div>
                <div class="text-sm md:text-base">underlie uses bank level security with the high-bit rate encryption and modern infrastructure to ensure your customer’s financial data is protected.</div>
            </div>
            </div>
            <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="500"  class="pr-4 mb-8">
            <img class="w-12 md:w-16 mb-4 mr-8" src="../../assets/icons/slider-2.svg" alt="">
            <div class="text-left max-w-xs">
                <div class="text-xl md:text-2xl font-bold mb-2">Brand flexible</div>
                <div class="text-sm md:text-base">We offer a white-labeled solution with best-in-class technology to build and launch data driven propositions for your customers without worrying about in-house development.</div>
            </div>
            </div>
            <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="1000" class="pr-4 mb-8">
            <img class="w-12 md:w-16 mb-4 mr-8" src="../../assets/icons/slider-3.svg" alt="">
            <div class="text-left max-w-xs">
                <div class="text-xl md:text-2xl font-bold mb-2">ISO 27001 Compliant</div>
                <div class="text-sm md:text-base">underlie’s systems are ISO27001 certified to ensure wekeep up with locally accepted bank security and encryption standards.</div>
            </div>
            </div>
            <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="1500" class="">
            <img class="w-12 md:w-16 mb-4 mr-8" src="../../assets/icons/slider-4.svg" alt="">
            <div class="text-left max-w-xs">
                <div class="text-xl md:text-2xl font-bold mb-2">One simple integration</div>
                <div class="text-sm md:text-base">In a matter of 24 hours you can start testing and running a few functionalities that elevate your customer engagement.</div>
            </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>