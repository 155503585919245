<template>
  <div>
    <!-- <div class="relative">
      <div data-aos="fade-up" :data-aos-offset="-3000" class="footer-accent1 w-full h-32"></div>
      <div class="footer-accent2 w-full h-32"></div>
    </div> -->
    <img data-aos="fade-right" data-aos-duration="1500" data-aos-delay="500" src="../assets/images/accent-2.svg" style="margin-bottom: -12rem" class="w-full" alt="">
    <svg class=curve xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
      <path fill="#2B3B4B" fill-opacity="1" d="M0,128L48,149.3C96,171,192,213,288,197.3C384,181,480,107,576,96C672,85,768,139,864,138.7C960,139,1056,85,1152,85.3C1248,85,1344,139,1392,165.3L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
    </svg>
    <div class="footer">
      <!-- <div class="list-wrapper flex justify-around">
        <div class="list">
          <div class="list-header">Products</div>
          <div class="list-item">Auth</div>
          <div class="list-item">Balance</div>
          <div class="list-item">Identity</div>
          <div class="list-item">Income</div>
          <div class="list-item">Transactions</div>
          <div class="list-item">Payments</div>
        </div>
        <div class="list">
          <div class="list-header">For Banks</div>
          <div class="list-item">API Docs</div>
          <div class="list-item">Libraries and SDKs</div>
          <div class="list-item">Okra Demo</div>
          <div class="list-item">Help Center</div>
          <div class="list-item">Slack</div>
        </div>
        <div class="list">
          <div class="list-header">For Fintechs</div>
          <div class="list-item">API Docs</div>
          <div class="list-item">Libraries and SDKs</div>
          <div class="list-item">Okra Demo</div>
          <div class="list-item">Help Center</div>
          <div class="list-item">Slack</div>
        </div>
        <div class="list">
          <div class="list-header">Legal</div>
          <div class="list-item">Developer Policy</div>
          <div class="list-item">Privacy Policy</div>
          <div class="list-item">Cookie Policy</div>
          <div class="list-item">End User Agreement</div>
          <div class="list-item">End User Privacy Policy</div>
        </div>
      </div> -->
      <!-- <hr> -->
      <div class="flex flex-col md:flex-row justify-between px-8 md:px-0 md:w-3/4 mx-auto items-center text-white">
        <div>
          <img @click="scrollToTop" src="../assets/images/logo-white.svg" class="h-6 cursor-pointer" alt="">
        </div>
        <!-- <div class="w-1/3 text-right">Designed By <span class="font-bold">Hashim</span></div> -->
        <div class="md:w-1/3 text-right text-xs md:text-base mt-4 md:mt-0">© Copyright 2022 underlie, Inc. All Rights Reserved.</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
};
</script>
