<template>
  <div class="overflow-hidden">

    <!-- Section 1 -->
    <!-- <div class="section-1 h-full relative">
      <div class="absolute top-0 h-full w-full pt-24 flex items-center justify-around" style="height:130%;">
        <img alt="Vue logo" class="w-2/5 self-start mt-20" src="../../assets/images/landing-2.jpg" />
        <div class="w-2/5 text-left text-white self-start mt-36">
          <div class="max-w-lg">
            <div class="text-5xl mb-4 typing min-h-36">
              <span class="">Simple APIs that transform your</span>
              <vue-typed-js :loop="true" :strings="['online banking apps', 'lending apps', 'investment apps', 'personal finance apps', 'insurance apps', 'e-commerce apps']" :showCursor="false" :typeSpeed="100">
                  <span class="font-bold text-5xl opacity-75 typing"></span>
              </vue-typed-js>
            </div>
            <div class="text-xl">Our secure APIs seamlessly integrate with banks and fintech companies to empower their products to make better, data-driven and automated decisions.</div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="mt-24">
        <div class="flex justify-around items-center">
            <img src="../../assets/images/landing-1.png" class="mt-20" alt="">
            <div class="max-w-xl text-left">
                <div class="text-main text-3xl opacity-50 uppercase font-bold">Data <span class="font-bold">API</span></div>
                <div class="text-6xl font-bold mb-8">Leverage spending patterns</div>
                <div class="text-xl my-4">Empower bank customers by leveraging spending patterns to tailor new benefits and services</div>
                <div>[This feature is currently only available for banks]</div>
                <div @click="$scrollToView('contact')" class="button bg-main rounded-lg text-white py-2 px-4 font-bold w-56 text-center h-12 flex justify-center items-center cursor-pointer mt-8">Read the Documentation</div>
            </div>
        </div>
    </div>
    <!-- <div class="relative z-m2" style="transform:scaleY(0.5)">
      <svg class="absolute top-0 z-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#082247" fill-opacity="1" d="M0,256L48,261.3C96,267,192,277,288,250.7C384,224,480,160,576,144C672,128,768,160,864,170.7C960,181,1056,171,1152,138.7C1248,107,1344,53,1392,26.7L1440,0L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path></svg>
      <svg :class="{'show' : showAccent1}" class="accent1 absolute top-0 z-m1 mt-12" style="transform:scale(1.3)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#004F78" fill-opacity="1" d="M0,256L48,261.3C96,267,192,277,288,250.7C384,224,480,160,576,144C672,128,768,160,864,170.7C960,181,1056,171,1152,138.7C1248,107,1344,53,1392,26.7L1440,0L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path></svg>
      <svg class="absolute top-0 z-m1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#009E83" fill-opacity="1" d="M0,32L80,74.7C160,117,320,203,480,229.3C640,256,800,224,960,229.3C1120,235,1280,277,1360,298.7L1440,320L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path></svg>
    </div> -->
    <!-- <div class="h-52 w-full"></div> -->

    <!-- Section 2 -->
    <!-- <div class="section-2 h-full relative">
      <div class="h-full w-full flex items-center justify-around">
        <div class="w-2/5 text-left">
          <div class="max-w-lg ml-auto">
            <div class="text-5xl mb-4 typing min-h-24">Powering modern fintech solutions</div>
            <div class="text-xl">Short for underlying technology, underlie leverages open-banking technology and machine learning to empower banks and fintech companies to provide their customers with better services and benefits. </div>
          </div>
        </div>
        <video style="margin-right: -30rem" data-testid="video" autoplay loop width="1024" height="544" poster="">
          <source src="../../assets/videos/landing-video.mp4" type="video/mp4">
        </video>
      </div>
    </div> -->
    <div class="px-36 min-h-30rem flex items-center">
        <div class="flex justify-around items-center w-full">
            <div class="flex flex-col items-center justify-center max-w-sm">
                <img src="../../assets/icons/personalize-bank-exp.png" class="w-28 mb-4" alt="">
                <div class="text-2xl font-bold">Personalized banking experience </div>
                <div class="bar-1 w-20 my-4 text-lg"></div>
                <div>Increase customer portfolio stickiness based on catered loan and merchant offerings </div>
            </div>
            <div class="flex flex-col items-center justify-center max-w-sm">
                <img src="../../assets/icons/personalize-bank-exp.png" class="w-28 mb-4" alt="">
                <div class="text-2xl font-bold">Personalized banking experience </div>
                <div class="bar-1 w-20 my-4 text-lg"></div>
                <div>Increase customer portfolio stickiness based on catered loan and merchant offerings </div>
            </div>
            <div class="flex flex-col items-center justify-center max-w-sm">
                <img src="../../assets/icons/personalize-bank-exp.png" class="w-28 mb-4" alt="">
                <div class="text-2xl font-bold">Personalized banking experience </div>
                <div class="bar-1 w-20 my-4 text-lg"></div>
                <div>Increase customer portfolio stickiness based on catered loan and merchant offerings </div>
            </div>

        </div>
    </div>

    <!-- Section 3 -->
    <!-- <div class="relative z-m2" style="margin-bottom: 20rem; margin-top: -3rem">
      <svg :class="{'show' : showAccent2}"  class="accent2 absolute top-0 z-1"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill="#E5E3E2" fill-opacity="1" d="M0,128L120,138.7C240,149,480,171,720,165.3C960,160,1200,128,1320,112L1440,96L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path>
      </svg>
      <svg class="absolute z-1 mt-12" style="transform: rotate(2deg); width: 130%; top: -3rem; left: -6rem;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill="#838180" fill-opacity="1" d="M0,128L120,138.7C240,149,480,171,720,165.3C960,160,1200,128,1320,112L1440,96L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path>
      </svg>
    </div> -->
    <hr style="height: 1px;">
    <div id="contact" class="w-full pb-40">
      <div class="text-center text-5xl text-main mb-12">Get In Touch</div>
      <div class="flex flex-col items-center justify-center w-1/2 mx-auto">
        <div class="flex w-full mb-6">
          <input class="w-full border border-main mr-3" type="text" placeholder="Name">
          <input class="w-full border border-main ml-3" type="email" placeholder="Email">
        </div>
        <textarea class="w-full border border-main" placeholder="Message" name="message" cols="30" rows="5"></textarea>
        <button type="submit" class="flex items-center text-xl mt-6 w-32 justify-center text-black bg-main text-white px-4 py-0 rounded-lg">
          Send
          <!-- <img src="../../assets/icons/direct.png" class="w-4 ml-3" alt=""> -->
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showAccent1: false,
      showAccent2: false
    }
  },
  methods: {
    handleScroll() {
      console.log(document.body.getBoundingClientRect().top)
      if (document.body.getBoundingClientRect().top < -200) {
        this.showAccent1 = true;
      } else {
        this.showAccent1 = false;
      }
      if (document.body.getBoundingClientRect().top < -900) {
        this.showAccent2 = true;
      } else {
        this.showAccent2 = false;
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  }
};
</script>
 
 <style lang="scss" scoped>
  .accent1 {
    top: -130px;
    transition: all 1s;
    &.show {
      top: 0;
    }
  }
  .accent2 {
    top: 130px;
    transition: all 1s;
    &.show {
      top: 0;
    }
  }
 </style>