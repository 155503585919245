<template>
    <div class="px-8 md:px-24 py-12 md:py-32" style="background: rgba(107, 120, 136, 0.06);">
        <div>
            <div class="font-bold text-2xl md:text-3xl header md:flex items-center">
                <img src="../../assets/images/logo-black.svg" class="w-40 mr-3" alt=""> 
                <div style="margin-top:9px;" class="text-left md:text-center">Open API Consult (beta)</div>
            </div>
            <div id="open-api" class="text-lg md:text-xl text-left mt-3">
                Underlie offers banks and financial institutions 25+ API calls to empower their solutions for 3rd party collaboration.
            </div>
        </div>
        <div class="mt-12 md:flex items-center">
            <div class="md:w-1/2">
                <div class="font-bold text-2xl md:text-4xl text-left">Currently</div>
                <div class="flex flex-wrap justify-between mt-8">
                    <div class="max-w-1/2 pr-4 md:pr-10 pb-10 flex-shrink-1">
                        <img src="../../assets/icons/i-score.svg" class="h-16" alt="I Score Icon">
                        <div class="text-lg font-bold text-left mt-3 mb-2">I-Score</div>
                        <div class="text-left max-w-xs">Mostly based on credit card history</div>
                    </div>
                    <div class="max-w-1/2 md:pr-10 pb-10 flex-shrink-1">
                        <img src="../../assets/icons/inaccurate.svg" class="h-16" alt="Inaccurate Icon">
                        <div class="text-lg font-bold text-left mt-3 mb-2">Inaccurate</div>
                        <div class="text-left max-w-xs">Their own equations based on mostly geography and assumptions</div>
                    </div>
                    <div class="max-w-1/2 pr-4 md:pr-10 pb-10 flex-shrink-1">
                        <img src="../../assets/icons/bank-statements.svg" class="h-16" alt="Statements Icon">
                        <div class="text-lg font-bold text-left mt-3 mb-2">Printed Bank Statement</div>
                        <div class="text-left max-w-xs">A manual procedure that companies request to further understand a customer</div>
                    </div>
                    <div class="max-w-1/2 md:pr-10 pb-10 flex-shrink-1">
                        <img src="../../assets/icons/unregulated.svg" class="h-16" alt="Unregulated Icon">
                        <div class="text-lg font-bold text-left mt-3 mb-2">Unregulated</div>
                        <div class="text-left max-w-xs">International open-banking platforms screen scraping customer information without bank consent</div>
                    </div>
                </div>
            </div>
            <div class="hidden md:block vr auto h-96 mr-10"></div>
            <hr class="md:hidden mb-20">
            <div class="md:w-1/2">
                <div class="font-bold text-2xl md:text-4xl header md:flex items-center">
                    <img src="../../assets/images/logo-alt.png" class="w-40 md:w-44 mr-3" alt="">
                    <div style="margin-top:9px;" class="text-left md:text-center">API Calls</div>
                </div>
                <div class="flex justify-between items-center mt-8">
                    <div class="flex flex-wrap justify-between">
                        <div class="max-w-1/2 pr-4 md:pr-10 pb-10 flex-shrink-1">
                            <img src="../../assets/icons/niche.svg" class="h-16" alt="Niche Services Icon">
                            <div class="text-lg font-bold text-left mt-3 mb-2">Tailored Niche Services</div>
                            <div class="text-left max-w-xs">Local merchants have a better understanding of customer spending habits, and cater deals accordingly</div>
                        </div>
                        <div class="max-w-1/2 pr-0 md:pr-10 pb-10 flex-shrink-1">
                            <img src="../../assets/icons/automation.svg" class="h-16" alt="Automation Icon">
                            <div class="text-lg font-bold text-left mt-3 mb-2">Automation</div>
                            <div class="text-left max-w-xs">An automated and secure way to connect your bank account</div>
                        </div>
                        <div class="max-w-1/2 pr-4 md:pr-10 pb-10 flex-shrink-1">
                            <img src="../../assets/icons/scalability.svg" class="h-16" alt="Scalability Icon">
                            <div class="text-lg font-bold text-left mt-3 mb-2">Scalability</div>
                            <div class="text-left max-w-xs">API calls run on a seperate local server that only processes and filters the information being requested</div>
                        </div>
                        <div class="max-w-1/2 pr-0 md:pr-10 pb-10 flex-shrink-1">
                            <img src="../../assets/icons/data-money.svg" class="h-16" alt="Data Money Icon">
                            <div class="text-lg font-bold text-left mt-3 mb-2">Data = Money</div>
                            <div class="text-left max-w-xs">Bank is aware of such a data share and receives a revenue from the merchant per request</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="min-w-1/2 w-full border rounded-3xl border-main px-10 py-10 flex flex-col justify-center text-white mt-8" style="background:#019E84;">
            <div class="text-2xl md:text-4xl">Reach out to us if you are a bank/financial institution that is intrested in modernizing their legacy systems for easier collaborations with new and existing partners.</div>
            <button type="submit" class="secondary mx-auto flex items-center text-xl mt-6 justify-center mt-8">
                <span>Get a Quote</span>
            </button>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>